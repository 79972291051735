import { convertMarkdownToHTML } from '../../../../utils/conversions';

export const journalSatan01 = convertMarkdownToHTML(`
  **Scénario 1 : Le Temple de Kiranof**

  Le journal d'un vieux...

  *Taverne*

  Je commence ce journal pour retranscrire l'aventure dans laquelle j'ai été embarqué. 
  Je suis allé voir mon ami Bob le tavernier à Kiranof, une petite ville tranquille située dans les plaines de Terfil.
  Contrairement à d'habitude le village était désert, si ce n'est 3 voyageurs : Un nain, et deux mutants.
  Nous avons convergé vers la taverne où un avis à s'occuper d'un problème était affiché. 

  Bob nous a accueillis bien triste, et pour cause il était le dernier sur place.
  En deux semaines, le village avait été déserté après qu'un mal s'est installé dans le temple d'Arlenor
  situé au-delà d'une colline proche du village. Cauchemars et disparition était le lot commun pour ceux restant ici. 
  Nous nous sommes présentés : Ramage un mutant (bien que ce n'était pas pas évident, on l'a appris bien plus tard)
  prospectant pour un lieu où s'installer et enfin Aly'nn et Ethan venus ensemble, respectivement le nain et le deuxième mutant,
  venus pour s'occuper de « monstres ». Et enfin Moi un Chronomancien nommé Malzarius venu voir Bob et prendre du repos.
  Nous avons conclu un accord pour avoir un cristal curieux que Bob prenait pour une simple pierre de valeur pour nous occuper du problème.
  Nous devions partir le lendemain après un bon repos mais durant la nuit, certains d'entre nous ont entendu des choses, ou ressenti une présence.
  Pour ma part c'était ma fille, et bien que je fusse conscient qu'elle ne pouvait pas être là, je n'ai pu m'empêcher de répondre à l'appel.
  Et j'ai crié Lina en sortant en pyjama. Ce qui heureusement a réveillé Ramage qui dormait à la belle étoile. 
  Ramage eut la bonne idée d'aller chercher les autres ainsi que mes affaires, pendant que moi, obsédé par la voix de ma fille,
  me dirigeais sans ma canne au-delà de la colline, vers le temple.

  *Temple (rdc)*

  Arrivés devant le temple, nous avons vu des corps. Un aventurier mort ainsi que des villageois.
  Nous avons pris notre courage à deux mains et sommes entrés à travers un portail d'ombre qui nous a emmené vers une version du temple
  en train d'être corrompu au fur et à mesure que nous montions.
  Nous avons croisé de nombreux cadavres, celui le plus étrange est un squelette qui semblait être mort bien avant les autres. 
  En explorant le rez de chaussé, nous avons vu un message en sang « Grelin est… » ainsi qu'une orbe.
  L'orbe permettait d'ouvrir une porte menant au reste du temple, et Ethan et moi avons eu une vision,
  montrant au-dessous et la présence qui bouleversait les lieux et avait tué tous les occupants. 
  (Je pensais vraiment faire une crise cardiaque pour le coup… Et Ethan était dans le même état donc je suppose que lui aussi)
  Nous avons trouvé des enfants morts, dans les latrines, mention tout de même d'une enfant où une illusion était superposée à un cadavre
  montrant un message de haine pour chaque race non humaine. Une enfant [insérer la race] avec un message « sale batârde de [insérer la race]
  écrit en sang pour chacun (sauf moi où c'était juste une pauvre fille).
  Je dois avouer qu'à ce point, nous étions terrorisés, et je remercie Ramage pour sa musique qui nous a permis de ne pas prendre
  nos jambes à notre cou et Aly'nn qui a su prendre les devants pour nous faire avancer.

  *Temple (étage)*

  A l'étage nous avons trouvé un dortoir et une classe.
  Dans la classe trois aventuriers morts ainsi que trois enfants « possédés » des cernes sous les yeux, faibles,
  décharnés et déshydratés. Dans le dortoir deux enfants dans le même état.
  J'ai essayé d'osculter un des enfants mais celui-ci a réagit lorsque je l'ai touché, tentant de m'aggriper.
  Les réflexes d'Aly'nn lui ont fait trancher ses bras et pendant que j'essayais d'empêcher
  l'enfant de mourir des tentacules sont sorti de son dos pour m'attaquer.
  Aly'nn n'eut d'autre choix que de le tuer.
  L'autre fût calmé par la musique de Ramage pendant qu'Ethan empêchait Aly'nn de tous les tuer.
  On a conclu que je pouvais d'abord osculter le corps du pauvre enfant avant qu'il ne décide quoi faire.
  J'ai pu découvrir que l'enfant n'était pas muté, et qu'on pouvait les sauver
  Nous avons donc barricadé la classe et sommes montés plus haut dans le temple.  

  *Temple (haut)*

  Là où le tentacule m'a touché, j'ai commencé à avoir des symptômes de l'épidémie qui m'a enlevé ma femme. 
  Une fois en haut, une rage anormale m'a submergé, et Ethan semblait être dans le même état.
  Nous avons ouvert la porte menant à l'origine du mal.
  Un enfant possédé dans une salle à l'aspect totalement changé, nous n'étions plus vraiment dans le temple d'origine à ce stade.
  Bien qu'il ait essayé de communiquer, sa vue avait amplifié notre rage.
  Ainsi Ethan et moi, nous nous sommes directement attaqué à lui. 
  L'enfant s'est transformé en un monstre gigantesque et effrayant, un être comme on en avait jamais vu
  et nous avons dû nous mettre à trois pour tenir face à lui.
  Jusqu'à ce qu'Ethan, dans une danse aussi terrifiante qu'impressionnante, le mis en pièce de ses sabres en sortant de l'ombre. 
  Le démon s'est alors rassemblé en se retransformant en l'enfant, endormi, alors que deux adultes se réveillait dans la pièce.
  Le démon dans un dernier soupire appela Ethan « Batard de Belzébuth » 
  Nous avons appris que de jeunes enfants avaient été utilisés comme catalyseurs pour enfermer des anges
  et des démons par une société appelée l'éveil. Le but d'abord noble fut vite corrompu par l'attrait du pouvoir une dizaine d'année,
  plutôt provoquant une scission dans les employés.
  Et devant nous Anabeth ainsi qu'Erick, parent du jeune enfant possédant en lui Nahémar, l'un de ces démons.
  Ils étaient membres de l'éveil avant de fuir pour protéger leur enfant.
  Nous avons appris qu'Ethan et Ramage aussi était des vaisseaux, peut être Aly'nn, mais pas moi. Trop « vieux » pour eux. 
  Nous sommes rentrés après avoir pris soin de nous occuper des enfants, de leur donner un peu à boire,
  un peu de nourriture et de les placer dans des lits. 
  Nous avons conclu qu'il valait mieux fuir et faire confiance en nos nouvelles connaissances fortuites
  pour échapper à cette « société de l'éveil »
  et surtout aux capacités d'Anabeth à pouvoir supprimer temporairement les pouvoirs en cas de manifestation. 
  Nous sommes partis chacun de notre côté avec un point de rendez-vous, ainsi que la pierre de Bob (comme promis)
  La pierre semble être complètement naturelle et pourtant comme un Crystal. 
`);