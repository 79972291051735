import { ModelAPI } from '../ModelAPI';
import { getEnumByCode, ModelEnum } from '../ModelEnum';
import { ArlenorRace } from './ArlenorRace';

export class ArlenorSkill extends ModelAPI {

  // Variables à sauvegarder
  public name: string;
  public description: string;
  public urlImage: string | null;
  public codeType: string;
  public codesCaracts: string[];
  public codeRace: string | null;

  // Variables dérivées
  public get image(): string | null {
    const url = (this.urlImage) ? '' + this.urlImage : 'other.png';
    return 'assets/images_filled/skills/' + url;
  }

  public get type(): ModelEnum {
    return getEnumByCode(this.codeType, SkillTypesEnum);
  }

  public get nameCaracts(): string {
    let results = '';
    this.codesCaracts.forEach((codeCaracts, index) => {
      if (index) results += ', ';
      results += codeCaracts;
    });
    return results ? results : 'Aucune caractéristique';
  }

  public get race(): ArlenorRace | null {
    if (!this.codeRace) return null;
    return ArlenorRace.getRace(this.codeRace);
  }

  constructor() {
    super();
    this.name = '';
    this.description = '';
    this.urlImage = null;

    this.codeType = SkillTypesEnum.Other.Code;
    this.codesCaracts = [];
    this.codeRace = null;
  }

  public init(name: string, type: ModelEnum): void {
    this.name = name;
    this.codeType = type.Code;
  }
}

export class SkillTypesEnum {
  public static Weapon: ModelEnum = { Code: 'WEAPON', Libelle: `Compétence d'arme` };
  public static Race: ModelEnum = { Code: 'RACE', Libelle: `Compétence de race` };
  public static Other: ModelEnum = { Code: 'OTHER', Libelle: `Autre compétence` };
}
