import { convertMarkdownToHTML } from '../../../../utils/conversions';

export const journalGabrielPersos = convertMarkdownToHTML(`
  **Les personnages**

  **Demetrius**, c'est un elfe arborant beaucoup de bijoux, amnésique et clerc.
  Il a eu le droit à une puberté express.
  D'un enfant relativement petit, il arrive presque à rivaliser avec ma taille. Et tout ça en moins de quelques semaines.

  **Kasyade** est un invocateur, grand, tatoué et végan.
  Il a bien changé depuis notre rencontre.
  Si on peut acquiescer qu'une chenille devenir un papillon, on peut très bien s'imaginer un tel changement chez l'humain.

  **Decim** est un barde collectionneur d'artéfacts, de bijoux et de conquêtes et se trouve être également un enfant-réceptacle.
  Note à part, je trouve ça un peu suspicieux de sa part de partager ce genre d'informations avec des inconnus.
  Toutefois, il nous rassure en rappellant qu'il est là après que nos deux mentors (?) lui soient apparus en rêve.

  Pour **Dynn**, on va donner que des informations vagues.
  Nous venons seulement de rencontrer Decim, je ne sais pas encore comment il réagit aux mutants.
  Mes compagnons de route, eux, sont déjà au courant mais il vaut mieux ne pas le crier sur tous les toits.
`);

export const journalGabriel01 = convertMarkdownToHTML(`
  **Scénario 1 : Le Temple de Kiranof**

  *En cours d'écriture...*
`);

export const journalGabriel02 = convertMarkdownToHTML(`
  **Scénario 2 : La Forêt d'Imerys**
  
  *Taverne*

  Ayant reçu la missive d'Érick à Kiranof, nous nous rendons avec Démitrius et Kasyade au village proche de la lisière de la Forêt d'Imérys.
  Notre contact sur place doit nous retrouver à la Taverne de la Sirène.
  Decim, un humain barde violoniste aux très belles cuisses nous accueille et nous entraîne vers la dite taverne.
  Le tavernier, Bob, nous mène à une table et nous laisse nous présenter avant d'apporter de quoi se sustenter. Tour de table rapide. 
  Bob nous donne pas mal d'informations personnelles concernant son arbre généalogique
  et sur les futures rencontres que nous pourrions faire dans d'autres villages et dans les alentours.
  Il nous présente également la forêt d'Imérys.
  Selon les rumeurs, seulement quelques personnes arrivent à sortir de cette forêt après y être rentrée.
  Il parait également d'un village de mutants de trouverait en plein milieu. 
  Notre objectif était de retrouver un enfant-réceptacle dans cette forêt.
  Finalement, faire un crochet dans ce village de mutants pourrait être une bonne idée. Nous passons la nuit et nous préparons pour notre expédition.

  *Forêt d'Imerys*
  
  Honnêtement, heureusement que nous nous sommes un minimum préparés.
  Il fait froid, humide, nous avons quelques trous dans le feuillage qui filtre la lumière.
  Le tonnerre tombe non loin mais à une fréquence assez élevée.
  Nos remarquons des sentiers marqués par le passage même si ce mot est un peu exagéré comparé à la réalité.
  Démitrius trouve un corps calciné derrière un fourré.
  Nous nous retrouvons face à une intersection.
  Un chemin semble être plus utilisé que l'autre. Kasyade fait apparaître un oiseau et l'envoie survoler la zone.
  Ce dernier repère un maison un peu plus loin. Pour y accéder, il faudrait prendre le chemin en mauvais état.
  Après une petite concertation, nous allons nous approcher de la maison avant de faire demi-tour et prendre l'autre chemin.

  *Maison mystérieuse*

	Une fois sur place, nous sommes arrivés devant une chaumière de bois. Une sorcière aurait pu y vivre paisible.
  Devant elle, se trouvait un arbre mort. Il semblerait que la foudre y tombe dessus assez régulièrement vu les traces calcinées qui le parcourent. 
  Après différentes conversations pour savoir si oui ou non on devait faire demi-tour, Decim frappe à la porte.
  S'en suit une partie de séduction entre le propriétaire et le barde. Quelques maladresses furent échangées.
  L'ambiance ici est étranger. L'homme de la maison nous appris que la maison appartenait à sa famille et qu'il vivait de ses cultures.
  Afin de créer un peu d'espace, je demande s'il était possible de récupérer quelques légumes pour la route.
  Il accepte et nous conduit à son potager. 
  Démitrius s'excuse, prétextant avec une envie pressante. Nous le laissons s'éloigner vers les toilettes sèches.
  En arrivant au potager, nous remarquons des plantes médicinales et nécessaires à la réalisation de concoctions.
  A minima, l'homme ne sait pas qu'il possède ces plantes ou alors il s'est bien caché de le préciser.
  Sur le chemin, nous lui demandons si un village existait bien sur ces terres et s'il pouvait nous indiqué le chemin.
  Alors que nous échangions ces informations, un craquement électrique retentit. Nous prenons tous le chemin de la maison.
  Sur le sol se trouvait Démitrius, proche de l'arbre. Le propriétaire devient beaucoup moins amical, demandant qu'on quitte rapidement les lieux.
  Nous en profitons pour prendre le chemin derrière la maison pour rejoindre le village rapidement.
  Avec un blessé sur les bras, nous ne passons pas inaperçu.
  Nous apercevons l'entrée du village. Sur le chemin, Démitrius nous expliqua ce qu'il avait fait.
  Il regardait à l'intérieur de la maison, identifiant des cristaux reliés les uns aux autres conduisant à l'étage.

  *Village en pleine forêt*

  L'ambiance ici est plus bucolique. Nous nous sentons à l'aise.
  Toutefois, notre arrivée n'annonçait pas des bonnes nouvelles pour les villageois qui s'enfermaient chez eux.
  Nous suivons l'allée principale menant à un temple.
  Nous n'en avons jamais vu des pareils aux travers des différents villages que nous avons pu traverser.
  Nous suivons les indications en direction d'un médecin et nous découvrons Amélia, une jeune femme blonde aux vêtements blancs (style prêtresse).
  Elle nous dirige vers chez son père, le médecin. Il prend en charge Démitrius.
  Amélia semble un peu trop intéressée par ses blessures au point d'en donner la chair de poule. Le groupe se scinde en deux :
  Démitrius, Decim et Kasyade et moi. Notre groupe se rend au temple pour prier. Le deuxième reste avec Amélia. 
  Durant l'excursion au temple, j'ai une vision d'Amélia, une aura blanche avec une boule sombre à l'intérieure.
  Rien de rassurant, nous repartons fissa voir nos compagnons.
  En arrivant, Démitrius dort et Decim et Amélia discutent.
  Nous apprenons qu'Amélia est une enfant-réceptacle, qu'elle connait Trémen, le magicien de la chaumière,
  qui mène des expériences douteuses dont actuellement sur une dryade
  et qu'elle a pour intension de récupérer un artéfact dans les ruines non loin du village (grande passion de Decim après les conquêtes).
  Finalement, tout le monde part se reposer. Kasyade invoque un chien pour monter la garde.
  Ce dernier nous réveille et nous apercevons Amélia sortir de la maison en pleine nuit.
  Nous la suivons discrètement dans le village, puis la forêt jusqu'aux fameuses ruines.

  *Ruines*

	Ces ruines ne nous rappellent rien. Leur architectures remontent plus loin que celles que nous connaissons.
  La végétation a repris le dessus. Ce bâtiment de jour ne doit pas inspirer confiance mais alors de nuit, c'est encore pire.
  Dans tous les cas, les traces nous ont amenées jusqu'ici. Nous n'avons pas d'autres choix que d'avancer.
  J'enroule les cristaux de feu au niveau de mes poings, on ne sait jamais ce qu'il pourrait se passer là-dessous.
  En entrant, nous allumons chacun une torche. Il fait très sombre. Nous arrivons à une première salle. Elle est assez spacieuse.
  Trois arbres sont positionnés en triangle autour d'une personne sur le sol. Il fallait s'en douter, il s'agit d'Amélia.
  Elle est entourée de lianes et semble endormie. Les trois arbres bougent légèrement. Je m'avance et commence à leur parler.
  Finalement, tout le groupe saisit l'occasion pour apporter des détails concernant la conversation avec les dryades.
  Si on peut esquiver un combat perdu d'avance, autant le faire. Nous proposons un marché avec elles :
  donner des informations sur le ravisseur qui a enlevé leur sœur contre Amélia. Elles ont l'air d'accepter.
  Kasyade en profite pour leur indiquer la position de la chaumière de Trémen (aka le proprio de la chaumière étrange) avec son oiseau.
  L'un des arbres disparait et revient quelques temps après accompagné de l'oiseau.
  Elles libèrent Amélia mais nous préférons qu'elles la gardent endormie le temps qu'on finisse d'explorer les ruines.
  Elles acceptent et nous ouvrent le passage vers une autre salle.
  Dans cette dernière, des fresques sont peintes.
  Elles représentent différents éléments de la création du monde et d'une guerre entre les serviteurs d'Arlenor.
  Sur la première, on peut voir Arlénor qui laisse le monde qu'il vient de créer à deux groupes,
  20 personnes au total, 10 anges et 10 démons (figures en noir et blanc).
  Sur la deuxième, une guerre éclatent entre deux groupes (10 vs 10). Chaque groupe étant constitué de figures blanches et noires. 
  Sur la troisième, un ange se désagrègent en tombant vers le bas, un ange se retrouve en haut de la fresque
  et les 18 personnages de l'histoire sont enfermés dans des cristaux.
`);

export const journalGabriel03 = convertMarkdownToHTML(`
  **Scénario 3 : Les Mines de Jirakan**

  *En cours d'écriture...*
`);

export const journalGabriel04 = convertMarkdownToHTML(`
  **Scénario 4 : La Lucifaniel**
  
  Cela fait quelques jours que nous sommes montés à bord du BAMM (Bateau à Moteur Magique).

  Nous nous sommes bien habitué aux lieu, et par réflexe ce matin nous nous sommes dirigés vers le bar du bateau.
  Nous y avons retrouvé Joffrey, le beau barman qui est également un enfant réceptacle habité par la divinité du partage.

  Il nous indique que Erick et Anabeth nous convient à une réunion, sûrement signe de nouveaux ennuis.

  On rechigne à y aller mais on ne nous laisse pas le choix.
  Nous y retrouvons tout le monde : Joffrey, Cléofée, Magnus, Zelana, Jessica, Amelia et Lysandre.

  On gueule un peu pour avoir de l'argent de poche (qu'on obtient) puis ils nous annoncent que la prochaine mission est une mission repos !!!
  Nous allons à la fête de Lucifugus organisé par la troupe du cirque.
  On pourra y croiser une autre enfant réceptacle mais le but n'est pas de la recruter, seulement de s'amuser pour une fois.

  On débat entre nous sur qui emporter avec nous au cirque devant les autres,
  le choix s'arrêtant sur Cleofée, Magnus, Joffrey, Zelana et Jessica.

  Nous arrivons en milieu d'après midi, le grand spectacle ne commençant que le soir nous nous décidons à faire le tour des animations.

  On commence par une mare aux canards avec cleofée tandis que Kasyade et Demitrius se sont essayé au stand de tir et de magie.

  Onfait le tour pour s'acheter des babioles puis on nous propose de participer à un concours
  le lendemain contre les troupes du cirque et des naines triplés siamoises.

  On s'est fait démarcher par une astrologue avec qui Kasyade s'est super bien entendue,
  nous dévoilant ses propres compétences pour lire dans les étoiles.

  Le temps d'inscrire nos équipes, le soleil se couche et vient l'heure du spectacle.
  Ils ont des acrobates, des la ceurs de couteaux, des dompteurs de bêtes sauvages,... Et notamment Melody qui était sublime !
  Dynn et Kasyade ont été enchantés par le spectacle, mais pas Demetrius et moi qui avons trouvé que le spectacle semblait un peu trop "charmant"
  
  On va ensuite vers la roulotte de la diseuse de bonne aventure pour avoir des prédictions sur notre avenir.
  C'était le bordel, entre des séances de divinations écourtées, des bourrasques de vent soudains,
  des pénis dessinés et des mains volés mais retrouvés.

  On a ensuite eu un moment de Team Building où on s'est tous dit la vérité,
  et on est ressorti encore plus pote que je ne pouvais l'imaginer c'était top !!!!
  Et comme de bons copains, on s'est tous retrouvé à poil aux bains du cirque.

  Kasyade a eu un moment spécial avec Joffrey que j'ai récupéré par la suite,
  Magnus était avec Dynn et Demettrius était là, il se repose tranquille.
  On rentre tous dormir et le lendemain matin on se retrouve autour d'une bonne bière du matin.
  Le retour fût compliqué pour Kasyade qui s'était endormi dehors mais aucun soucis.

  J'ai reçu une lettre mystérieuse que je décide d'ouvrir à l'écart plus tard,
   pensant qu'il s'agit d'un message d'un énième amant dont je ne souhaite pas que les sentiments
   soient connus des autres par respect à son égard. (Flemme de lire tout de suite)

  C'est l'heure du grand concours avec comme équipes :
  Les Pffff (dynn, Magnus, zelana)
  Les BG Mécheux (Joffrey, Demetrius, Kasyade)
  Les Queens (decim, Jessica, Cleofée)
  Les Multifaces (Thalios, Melionas, Arlequine)
  Les Baladeurs (Pedro, Melody, Keith)
  Les Nain-phètes (les 3naines siamoises)

  Le concours se déroule sans magie grâce à des bloqueurs (Dynn perd la main lol).

  La course était épique. Pendant que je bouffais le sol pendant 20ans, les multi faces ont gagné ex-aequo avec les baladeurs, suivi des Pff

  Nous devons ensuite choisir de participer à 3 épreuves sur 4 entre:
  Lancer de dague
  Tir magique
  Création de potion
  Marteau

  (Je ne sais plus les résultats finaux)

  On doit ensuite réfléchir à une prestation que l'on doit faire le lendemain pour l'épreuve finale.
  On réfléchit à tricher mais les autres pensent que ce n'est pas "éthique"
  On doit tout donner pour gagner une pierre qui permet d'exaucer un souhait (c'est le sentiment qui s'en dégage de la pierre)

  Le soir on se sépare .
  Pendant que Kasyade, Magnus, Dynn, Demetrius, Joffrey, et Zelana vont aux bains,
  Cleofée, Jessica et moi allons au spectacle.

  Mais c'est alors que se produit un Clifhanger pour tous ceux qui n'étaient pas au spectacle...

  Des cris s'élèvent dans le chapiteau, puis viennent la peur et la panique.
  Toute cette agitation alerte le groupe qui était dans les bains qui se ruent en direction des hurlements
  (après s'être habillés tout de même)
  Pendant que demetrius se fait piétiner en tentant de rentrer, le reste du groupe parvient au cœur du chapiteau.
  Au centre se trouve Jessica et Decim, ainsi que cléofée.
  Ses mains étaient ensanglantées et le visage couvert de larmes, elle tentait vainement de soigner le corps d'une personne
  dont les cheveux roses se teignaient désormais de rouge.
  C'est à ce moment que Irma arrive, terrifiée, ainsi que le reste de la troupe qui eux semblaient garder un visage plus impassible.
  Seuls manquaient à l'appel Pedro et Keith.
  Kasyade s'approche de Jessica et moi afin d'en savoir plus sur la situation.
  Pendant la représentation, quand ce fut le tour de Pedro et Mélody, ils exécutèrent leur classique numéro d'acrobatie.
  Au moment où Pedro dû rattraper Melody, ce fut comme si leurs mains s'effleuraient sans parvenir à se toucher,
  puis cela mena à la chute de la belle acrobate.
  Jessica tiqua, expliquant que c'était bien pire que ça, c'est comme si Pedro n'avait jamais été là, comme une illusion.

  Pendant ce temps là, Irma examina le corps de Melody et eu comme un flash. Elle ordonna l'évacuation du public,
  cette affaire ne concernant que la troupe du cirque.

  Une fois hors du chapiteau, Demetrius tenta d'espionner la troupe grâce à son sort de transformation élémentaire.
  Mais égal à lui-même, il se fit attraper.
  Alors qu'il s'approchait de la troupe, sa magie se stoppa net comme si elle avait disparus.
  Thalios l'immobilisa d'un claquement de doigt, désignant le captif comme suspect principal du décès de leur acrobate vedette.
  Un meurtier  revient toujours sur la scène de crime.

  Pendant ce temps, Kasyade sniffa de la terre (pour pister)
  Accompagné de Laïri ( qui venait seulement de se réveiller dans le bateau),
  ils suivirent une piste qui les mena dans la forêt à l'arrière.
  Ils y retrouvèrent Pedro et Keith inconscient au milieu d'une petite clairière.
  Après s'être approchés, ils virent de faibles traces de luttes dans la zone, du sang à l'arrière de leurs crânes.
  Après que Laïri leurs ait appliqué les premiers soins, Pedro se réveille un peu hagard
  et expilque qu'il discutait tranquilement avec Keith avant le début du show puis ont entenus du bruit provenant de la forêt,
  avant de ne plus se soouvenir de quoi que ce soit.
  Kasyade en profite pour lui confisquer sa flûte sous yeux, puis lui annonce avec « tact » la mort de Melody.
  Pedro bugue, réveille Keith pour lui annoncer la nouvelle et ce dernier s'effondre en larme.
  Laïri tenta de détecter des traces de magies possibles sur eux mais ne trouva rien.

  Dans le même temps, Dynn pénétra dans le chapiteau pour secourir Demetrius qui avait appelé au secours
  avant d'être magiquement bailloné. Mélionas ne perdit pas de temps et déclara Dynn comme second suspect.
  Dynn se lança dans une partie de Phoenix Wright en questionnant la troupe et défendant sa présence et celle de Demetrius.
  Mais Dynn n'a jamais réussi à passer le barreau et se fait gentiment intimer l'ordre de sortir. 'Nuh uh' dit il.
  Dynn se retrouva donc immobilisé à côté de Demetrius (mais volontairement)
  Kasyade, Laïri, Pedro et Keith reviennent tranquillement près du chapiteau
  et tombe sur Decim qui essayé de piller les stands de marchand mais sans succès.
  On décide d'aller voir ensemble pourquoi Demetrius et Dynn mettent autant de temps.
  Kasyade entre dans le chapiteau en fanfare, présentant Pedro comme le coupable qu'il a vaillamment trouvé et arrêté,
  tandis que Keith s'effondre sur place à la vue du corps inerte de l'acrobate.
  On est surpris de retrouver Dynn dans cette situation (mais pas pour Demetrius),
  Kasyade plaide sa cause prétextant qu'il s'agit du quota handicapé du groupe, puis parvient à echanger la place de Dynn avec Pedro.
  La troupe laisse une chance à Demetrius de s'expliquer, mais un mot.
  Il choisit « Curiosité », et suite à nos soupirs assourdissants, il est libéré de son entrave.
  Malgrès notre insistance, la troupe refuse notre aide, et annonce que le spectacle continuera tout de même le lendemain. « Show must go on »
  Nous rentrons au bateau et Decim en profite pour lire enfin la lettre, qu'il brûle minutieusement afin de ne laisser aucune trace.
  La nuit passe, puis vient l'heure de la bière matinale.
  Demetrius tente de faire ami-ami avec Zelana et Magnus mais se prend un vent monumental
  On se fait tous un petit topo sur les évènements d'hier et les projets de la journée.
  On doit tout miser sur la prestation des BGmécheux afin de gagner la pierre,
  et essayer d'en savoir plus sur la mort « accidentelle » de Melody. 
  Une fois revenus à la foire, on constate que différentes scènes ont été installés sur l'ensemble des lieux
  quand Irma s'avance pour remplir son rôle de maître de cérémonie.

  Elle déclare que l'évènement d'hier n'était qu'un malheureux accident, que ce n'est pas la première fois
  que cela arrive et que le reste de la compétition rendra hommage à l'étoile du cirque qu'incarnait Melody.
  Cependant, le groupe des « Baladeurs » ne peut plus concourir mais participera tout de même au vote final.
  Il nous est laissé la journée pour préparer nos prestations.
  L'ordre de passage des groupes est dans l'ordre inverse du classement, et c'est aux « PFFFFFFF » que revient l'honneur de commencer.
  Ils exécutèrent un mélange de capoeira et de danse dans les bains, mettant en avant les mouvements gracieux de Zelana,
  les compétences techniques de Magnus qui créa des jeux d'eau ainsi que la bioluminescence de Dynn
  qui pour l'occasion avait recouvert son corp de poils grâce à une pierre spécial en sa possession.
  Mais au lieu de recevoir des applaudissements, leur groupe reçus des huées et des lancés de projectiles qu'ils évitèrent agilement.
  Le public est plus impressionné par ces esquives que le spectacle, Irma en est « mouillée » ;
  Ce fut ensuite le tour des Nain-phètes. Alors qu'elles se préparaient,
  Laïri enquêta sur les environs mais fut à deux doigts de se mettre à dos Arlequina qui gardait la scène près du chapiteau.
  Le spectacle des Nain-phètes consistait en du jonglage et de la magie.
  Pouf les objets du marchand de potion disparaissaient. Pouf les armes et armures du forgeron se volatilisaient.
  Puis dans un grand pouf de fumée, les trois sœurs siamoises triplées se dérobèrent à nos yeux.
  Juste pendant 5secondes, avant qu'on ne les prenne sur le fait en train de s'enfuir.
  La plus grande surprise était le fait qu'il s'agissait juste de 3 naines dans un vêtement trop grand.
  Pendant qu'Irma annonce leur disqualification, nous applaudissons pour l'effort.
  Vient le tour des Queens, qui réalise un concert de Rock-Métal avec Cléofée aux explosions
  et Jessica à la guitare électrique. Alors qu'ils s'éclatent sur scène, le public reste dubitatif
  et on passe doucement au groupe suivant.
  Les BGM se produisent également au sauna.
  Dire que leur spectacle est un diamant pour les yeux est un euphémisme.
  Ils commencent par une presta	tion pôle-dance qui met en avant leurs attributs
  et leur grâce sur de longs poteaux qui glissent entre leurs cuisses et mains musclées.
  Joffrey réussit à capter la majorité des regards du public et semble s'en délecter.
  Il se mêle à leur prestation un spectacle de magie alliant manipulation d'eau et de feu, lumières,
  puis des feux d'artifices grâce à l'invocation du familier de Kasyade et les explosifs de Joffrey.
  Explosifs plutôt surdosés mais qui ne font qu'augmenter l'excitation du public et d'Irma dont les arguments vibrent d'excitation.
  Enfin, ce fut au tour des Multifaces de réaliser leur présentation pour clore la série de spectacle.
  Leur prestation était un savant mélange de jonglages de cartes, lames et de passe d'armes
  qui s'étendait sur toutes la foire. On sentait qu'ils maitrisaient leur sujet,
  mais les ovations du public étaient plutôt dû à leur réputation qu'au spectacle.
  Après un dernier échange du côté des stands de tirs, ils se font tous atteindre par leurs derniers projectiles
  avant de disparaitre comme une illusion qui se dissipe. On les retrouve finalement sur la scène de départ.
  Sur la technique on leur met 10, sur l'artistique à peine 2, too much et maintream.

  La dernière cérémonie se passe à l'intérieur du chapiteau où l'on procède aux votes du public puis entre groupe.
  Mais sur le chemin Demetrius part en direction des stands de tir avec Decim. Laïri est quasi certain
  d'avoir vu Pedro se prendre une lame lors de la prestation des Multifaces.
  Il fait nuit et la recherche est ardu, on ne trouve que des traces de sang et l'une des dagues du spectacle qui git au sol.
  Nous suivons discrètement les traces qui nous mènent au bateau. Mais partout autour et à l'intérieur,
  aucune trace de Pedro ni même de sang, et aucuns témoins.
  Sous le chapiteau, les résultats tombent. Les Multifaces gagnent les votes des groupes suivis des BGM
  et des Pfffff, mais le résultat du Public renverse la donne en attribuant 3 points aux BGM et deux points aux Queens.
  C'est donc une superbe victoire des BGM qui mènent de 2 points face aux Multifaces.
  Le temps que Demetrius et Decim reviennent dans la tente, Kasyade et Joffrey (toujours en Jockstrap) reçoivent la pierre de souhait.
  Kasyade nous sort un discours vraiment émouvant, Joffrey annonce qu'il est grave chaud
  et Demetrius fanfaronne devant Arlequina (Cheh qu'il lui balance).
  On profite des moments suivant pour essayer de glaner des informations.
  Keith et Irma discutaient avant de se séparer à notre arrivée,
  Dynn parle avec Mélionas, Thalios et Arlequina qui expliquent que la pierre de vœux est un objet que l'on peut décrire comme « divin ».
  la Pierre leur a été fournis par un sponsor.
  Demetrius, qui contre l'avis de tout le monde s'est joint au groupe, demanda où était passé Pedro.
  Demetrius se prie un second vent ce jour-là.
  Au même moment, Kasyade questionna un peu Keith mais ce dernier n'avait aucune mémoire des derniers évènements.
  Il explique que Pedro a pris ses distances avec le groupe et est repartis chez sa famille mais reviendra un jour.
  Kasyade joue franc jeu en lui disant que l'on a aperçu son potentiel cadavre mais Keith ignora cette remarque.
  Laïri joua encore plus franc jeu en abordant la lame et dmanda si quelqu'un pouvait leur en vouloir.
  Keith se renferme sur lui-même et part en maugréant « Justice a été rendus ».
  Souhaitant avoir le dernier mot, Kasyada le menaca avant de partir lui aussi.

  Avant de rentrer au bateau, Decimva voir Irma mais elle n'est pas loquace pour un sous,
  expliquant elle aussi que « Justice a été rendus ». Decim n'y crois pas un moment et se dit que cette histoire pue du cul.
  Kasyade discute un peu également avec Irma mais perosnne ne sait sur quoi.
  Lorsque l'on rentre au vaisseau, tout est d'abord calme.
  Soudain, un mouvement de panique s'élève dans tout le vaisseau puis Anabeth apparait au détour d'un couloir.
  Elle pète un câble et hurle d'une force inouïe.
  C'est alors que chacun de nous se réveille en sursaut dans nos cabines respectives.
`);